import { array, type GenericSchema, type InferOutput, intersect, lazy, nullish, object, picklist } from 'valibot'
import { baseAuthorSchema } from './author'
import { baseBodySchema } from './body'
import { type BaseComment, baseCommentSchema } from './comment'
import { type BaseLaw, baseLawSchema } from './law'

export const nodeTypeSchema = picklist([
  'law',
  'chapter',
  'section',
  'subsection',
  'del',
  'paragraph',
  'note',
])

export type NodeType = InferOutput<typeof nodeTypeSchema>

export type BaseBody = InferOutput<typeof baseBodySchema>

export type BaseAuthor = InferOutput<typeof baseAuthorSchema>

/* Due to a TypeScript limitation, the input and output types of recursive schemas cannot be inferred automatically.
Therefore, you must explicitly specify these types using GenericSchema.
*/

type CommentDocs = BaseBody & {
  docs: Doc[]
}

type Doc = BaseLaw & BaseComment & BaseAuthor & {
  nodeType?: NodeType | null
  children?: Doc[] | null
  commentDocs?: CommentDocs | null
}

export const commentDocsSchema = intersect([
  baseBodySchema,
  object({
    // eslint-disable-next-line ts/no-use-before-define
    docs: array(lazy(() => docSchema)),
  }),
])

export const docSchema: GenericSchema<Doc> = intersect([
  baseLawSchema,
  baseCommentSchema,
  baseAuthorSchema,
  object({
    nodeType: nullish(nodeTypeSchema),
    children: nullish(array(lazy(() => docSchema))),
    commentDocs: nullish(commentDocsSchema),
  }),
]) as unknown as GenericSchema<Doc> // TypeScript need helps to infer the type of recursive schemas when using transform

export type SolrDoc = InferOutput<typeof docSchema>
