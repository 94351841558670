import { array, type InferOutput, nullish, object, string } from 'valibot'

export const baseAuthorSchema = object({
  title: nullish(string()),
  shortBio: nullish(string()),
  remoteId: nullish(string()),
  image: nullish(array(string())),
  bodytext: nullish(string()),
})

export type BaseAuthor = InferOutput<typeof baseAuthorSchema>
