export const SolrParamsKey = {
  ContentType: 'contentType[]',
  FullText: 'fullText',
  LawId: 'lawId',
  PathLocation: 'pathLocation',
  CommentIdsByLawId: 'commentIdsByLawId',
  CommentByEzMainNodeId: 'commentByEzMainNodeId',
  SearchCommentAndLaw: 'searchCommentAndLaw',
  Authors: 'authors',
  Author: 'author',
} as const

export interface SolrParams {
  [SolrParamsKey.ContentType]?: string[] // fetches by nodeType, f.ex. ['law', 'chapter']
  [SolrParamsKey.FullText]?: string // full text search
  [SolrParamsKey.LawId]?: string // fetches all documents related to a law
  [SolrParamsKey.PathLocation]?: number // fetches all documents related to a path location, applies to Ibexa documents
  [SolrParamsKey.CommentIdsByLawId]?: string // fetches legal commentary IDs related to a law ID
  [SolrParamsKey.CommentByEzMainNodeId]?: string // fetches a legal commentary document by a main node ID
  [SolrParamsKey.SearchCommentAndLaw]?: CommentAndLaw // full text search for a comment and law defined by lawId and commentId
  [SolrParamsKey.Authors]?: string // fetches all documents related to a path location, applies to Ibexa documents
  [SolrParamsKey.Author]?: string // fetches all documents related to a slug (remote ID)
}

export interface CommentAndLaw {
  lawId?: string
  commentId?: number
}
