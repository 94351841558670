import { array, fallback, type InferOutput, intersect, literal, nullish, number, object, omit, pipe, string, transform } from 'valibot'

/**
 * Schema for shared Solr law properties.
 */
export const baseLawSchema = object({
  id: string(),
  ministry: nullish(string()), // Departement
  dateOfPublication: nullish(string()), // Kunngjort dato
  dateInForce: nullish(string()), // I kraft dato
  lastChangeInForce: nullish(string()), // Sist endret i kraft dato
  source: nullish(string()), // Kilde
  title: nullish(string()),
  shortTitle: nullish(string()),
  miscInformation: nullish(string()), // Annet informasjon
})

export type BaseLaw = InferOutput<typeof baseLawSchema>

/**
 * Schema for law Solr documents.
 *
 * This schema is used to validate Solr documents that represent laws.
 */
export const lawPropertiesSchema = intersect([
  omit(baseLawSchema, ['ministry', 'dateOfPublication', 'source']), // Omitting these properties because we don't need them when displaying list of laws
  object({
    nodeType: fallback(nullish(literal('law')), 'law'),
    comments: object({
      numFound: number(),
      docs: nullish(array(object({
        shortTitle: nullish(string()),
      }))),
    }),
  }),
])

/**
 * Schema for Solr law response
 */
export const lawSchema = pipe(
  lawPropertiesSchema,
  transform((it) => {
    const { comments, shortTitle, ...rest } = it

    return ({
      ...rest,
      shortTitle: comments?.docs?.[0]?.shortTitle ?? shortTitle, // Use short title from comments if available
      has_commentary: comments.numFound > 0, // This field indicates whether the law has commentary
      is_repealed: rest.miscInformation?.includes('opphevet'), // This field indicates whether the law is repealed
    })
  }),
)

export type Law = InferOutput<typeof lawSchema>
