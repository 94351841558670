import { array, type InferOutput, nullish, number, object, pipe, string, transform } from 'valibot'

export const baseCommentSchema = pipe(
  object({
    lawFragmentId: nullish(string()),
    lawId: nullish(string()),
    authorName: nullish(array(string())),
    timestamp: string(),
    bodytext: nullish(string()),
    chapterTitle: nullish(string()),
    paragraphTitle: nullish(string()),
    path_location: nullish(array(string())),
    ezMainNodeId: nullish(number()),
  }),
  transform((it) => {
    const { authorName, ...rest } = it
    return ({
      ...rest,
      authors: [...(authorName ?? [])],
    })
  }),
)

export type BaseComment = InferOutput<typeof baseCommentSchema>
