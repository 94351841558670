import type { SolrParams } from '#shared/solr'
import { array, type InferOutput, intersect, nullish, object, parse } from 'valibot'
import { baseBodySchema } from '~/utils/solr/body'
import { docSchema } from '~/utils/solr/doc'
import { headersSchema } from '~/utils/solr/headers'
import { solrHighlightingSchema } from '~/utils/solr/highlighting'

export const solrContentResponseSchema = object({
  responseHeader: headersSchema,
  response: intersect([
    baseBodySchema,
    object({
      docs: array(docSchema),
    }),
  ]),
  highlighting: nullish(solrHighlightingSchema),
})

export type SolrContentResponseSchema = InferOutput<typeof solrContentResponseSchema>

export function useSolrQuery(endpoint: 'search' | 'content', params: MaybeRef<SolrParams>) {
  return useQuery({
    queryKey: ['solr', endpoint, params],
    enabled: computed(() => {
      const filteredKeys = Object.entries(unref(params)).filter(
        ([, value]) => value !== undefined && value !== null,
      )
      return filteredKeys.length > 0
    }),
    async queryFn({ signal }) {
      const res = await $fetch(`/api/solr/${endpoint}`, {
        params: unref(params),
        signal,
      })

      return parse(solrContentResponseSchema, res)
    },
  })
}
